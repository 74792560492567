import { DownloadOutlined, FilePdfOutlined } from "@ant-design/icons";
import { Button, Flex, Space, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useUser } from "../../contexts/ContextProvider";
import { supabaseClient } from "../../utility";
import PdfViewer from "../common/PdfViewer";
import InvoiceResendButton from "./InvoiceResendButton";

const { Text } = Typography;

interface AddressDisplayProps {
  invoice: any;
  label?: string;
}

const AddressDisplay: React.FC<AddressDisplayProps> = ({ invoice, label }) => {
  const [invoicePdfUrl, setInvoicePdfUrl] = useState<string | null>(null);
  const { currentTeam } = useUser();

  useEffect(() => {
    const fetchFileUrl = async () => {
      const filePath = `teams/${currentTeam?.account_id}/invoices/${invoice.invoice_number}.pdf`;

      // Check if the file exists
      const { data: fileExists, error: listError } =
        await supabaseClient.storage
          .from("files")
          .list(`teams/${currentTeam?.account_id}/invoices`, {
            limit: 1,
            search: `${invoice.invoice_number}.pdf`,
          });

      if (listError || !fileExists || fileExists.length === 0) {
        return;
      }

      // Generate a downloadable URL
      const { data, error: downloadError } = await supabaseClient.storage
        .from("files")
        .download(filePath);

      if (downloadError) {
        console.error("Error creating download URL:", downloadError);
        return;
      }

      const blobUrl = window.URL.createObjectURL(data);
      const uniqueUrl = `${blobUrl}?t=${new Date().getTime()}`;
      setInvoicePdfUrl(uniqueUrl);
    };

    fetchFileUrl();

    return () => {
      if (invoicePdfUrl) {
        window.URL.revokeObjectURL(invoicePdfUrl);
      }
    };
  }, [currentTeam?.account_id, invoice?.invoice_number]);

  async function downloadFile() {
    if (!invoicePdfUrl) {
      console.error("No file URL available for download.");
      return;
    }

    const a = document.createElement("a");
    a.href = invoicePdfUrl;
    a.download = `${invoice.invoice_number}.pdf`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  if (!invoicePdfUrl) {
    return null;
  }

  return (
    <Flex vertical gap={16}>
      {label && <Text strong>{label}:</Text>}
      <Space style={{ width: "100%", justifyContent: "center" }}>
        {invoice?.invoice_number && invoicePdfUrl && (
          <InvoiceResendButton invoice={invoice} />
        )}
        <Button onClick={downloadFile}>
          <Space>
            <DownloadOutlined />
            <FilePdfOutlined />
            <Typography.Text>
              {" "}
              {`${invoice?.invoice_number}.pdf`}
            </Typography.Text>
          </Space>
        </Button>
      </Space>

      <div style={{ maxWidth: "450px" }}>
        <PdfViewer fileURL={invoicePdfUrl} />
      </div>

    </Flex>
  );
};

export default AddressDisplay;
